import React from 'react'
import decre_logo from '../../assets/images/decre_logos/2.png'

const Landing = () => {

  return (
    <div style={{backgroundColor: '#023750', height: '100vh'}}>
      <img style={{paddingTop: '200px'}} src={decre_logo} />
    </div>
  )
}

export default Landing
